import http from "../utils/http";

export function login(data) {
  return http({
    url: "/admin/login",
    method: "post",
    data,
  });
}
// 修改个人信息
export function changeInfo(data) {
  return http({
    url: "/admin/changeInfo",
    method: "post",
    data,
  });
}
// 个人信息
export function info(params) {
  return http({
    url: "/admin/info",
    method: "get",
    params,
  });
}
// 用户列表
export function userList(params) {
  return http({
    url: "/admin/user/list",
    method: "get",
    params,
  });
}
// 用户列表
export function userDel(params) {
  return http({
    url: "/admin/user/del",
    method: "delete",
    params,
  });
}
// 修改用户列表
export function userEdit(data) {
  return http({
    url: "/admin/user/edit",
    method: "put",
    data,
  });
}
// 修改密码
export function changePwd(data) {
  return http({
    url: "/admin/changePwd",
    method: "post",
    data,
  });
}
// 退出登录
export function logout(data) {
  return http({
    url: "/admin/logout",
    method: "post",
    data,
  });
}
// 修改关于我们
export function configSet(data) {
  return http({
    url: "/admin/config/set",
    method: "post",
    data,
  });
}
// 关于我们
export function configGet(params) {
  return http({
    url: "/admin/config/get",
    method: "get",
    params,
  });
}
// 公告列表
export function noticeList(params) {
  return http({
    url: "/admin/notice/list",
    method: "get",
    params,
  });
}
// 公告列表添加
export function noticeAdd(data) {
  return http({
    url: "/admin/notice/add",
    method: "post",
    data,
  });
}

// 公告列表详情
export function noticeDetail(params) {
  return http({
    url: "/admin/notice/detail",
    method: "get",
    params,
  });
}
// 公告列表修改
export function noticeEdit(data) {
  return http({
    url: "/admin/notice/edit",
    method: "put",
    data,
  });
}

// 公告列表删除
export function noticeDel(params) {
  return http({
    url: "/admin/notice/del",
    method: "delete",
    params,
  });
}

// 文章分类列表
export function classList(params) {
  return http({
    url: "/admin/article/class/list",
    method: "get",
    params,
  });
}

// 文章分类列表
export function articleList(params) {
  return http({
    url: "/admin/article/list",
    method: "get",
    params,
  });
}
// 文章删除
export function articleDel(params) {
  return http({
    url: "/admin/article/del",
    method: "delete",
    params,
  });
}

// 文章添加
export function articleAdd(data) {
  return http({
    url: "/admin/article/add",
    method: "post",
    data,
  });
}
// 文章详情
export function articleDetail(params) {
  return http({
    url: "/admin/article/detail",
    method: "get",
    params,
  });
}
// 文章编辑
export function articleEdit(data) {
  return http({
    url: "/admin/article/edit",
    method: "put",
    data,
  });
}

// 打赏列表
export function donateList(params) {
  return http({
    url: "/admin/donate/list",
    method: "get",
    params,
  });
}

// 反馈列表
export function feedbackList(params) {
  return http({
    url: "/admin/feedback/list",
    method: "get",
    params,
  });
}

//模板列表
export function taskList(params) {
  return http({
    url: "/admin/task/list",
    method: "get",
    params,
  });
}
//添加模板
export function taskAdd(data) {
  return http({
    url: "/admin/task/add",
    method: "post",
    data,
  });
}

//删除模板
export function taskDel(params) {
  return http({
    url: "/admin/task/del",
    method: "delete",
    params,
  });
}

//删除模板
export function configsGet(params) {
  return http({
    url: "/admin/configs/get?flag=uploadtype,domain,accesskey,secretkey,bucket,region",
    method: "get",
    params,
  });
}
//添加模板
export function configsSet(data) {
  return http({
    url: "/admin/configs/set",
    method: "post",
    data,
  });
}