import React, { useState, useEffect } from "react";
import { Button, Table, Input, message, Typography } from "antd";
import "./RoleList.css";
import { userList, classList } from "../../../api/login";
// import ModelModule from "./ModelModule";
export default function UserList() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableLoading, settableLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [dataList, setdataList] = useState([]);
  const [total, settotal] = useState([]);
  useEffect(() => {
    settableLoading(true);
    classList({
      page: page,
      limit: 10,
    }).then((res) => {
      settableLoading(false);
      setdataList(btn(res.data));
      settotal(res.data.count);
    });
  }, [page]);

  const columns = [
    {
      title: "ID",
      dataIndex: "classId",
      align: "center",
    },
    {
      title: "分类名称",
      dataIndex: "className",
      align: "center",
    }
  ];

  // 处理表格数据带上key
  const btn = (list) => {
    const data = [];
    for (let i = 0; i < list.length; i++) {
      data.push({
        key: i,
        classId: list[i].classId,
        className: list[i].className
      });
    }
    return data;
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // 单元格选中
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  // 分页change
  const tableChange = (e) => {
    setpage(e.current);
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div>
      <Table
        rowKey={(row) => row.classId}
        onChange={tableChange}
        loading={tableLoading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataList}
        pagination={{
          total: total,
        }}
        scroll={{ y: "calc(100vh - 300px)" }}
      />
    </div>
  );
}
