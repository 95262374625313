import React, { useState, useEffect } from "react";
import { Editor, Toolbar } from "@wangeditor/editor-for-react";
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { Button, message } from "antd";
import { configGet,configSet } from "../../../api/login";
export default function AboutUs() {
  const [editor, setEditor] = useState(null);
  const [html, setHtml] = useState("<p>hello</p>");
  useEffect(() => {
    configGet({flag:'aboutus'}).then(res=>{
        if(res.code==200){
            setHtml(res.data.content); 
        }
    })
  }, []);
  // 工具栏配置
  const toolbarConfig = {};
  // 编辑器配置
  const editorConfig = {
    placeholder: "请输入内容...",
    MENU_CONF: {},
  };
  editorConfig.MENU_CONF["uploadVideo"] = {
    server: "https://api.songci.gocli.run/admin/upload",
    fieldName: "file",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    // 上传之前触发
    onBeforeUpload(file) {
      return file;
    },

    // 上传进度的回调函数
    onProgress(progress) {
      console.log("progress", progress);
    },
    // 自定义插入图片
    customInsert(res, insertFn) {
      console.log(res, "fanhuishuju");
      let url = res.data.path;
      insertFn(url);
    },
  };
  editorConfig.MENU_CONF["uploadImage"] = {
    server: "https://api.songci.gocli.run/admin/upload",
    fieldName: "file",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    // 上传之前触发
    onBeforeUpload(file) {
      return file;
    },

    // 上传进度的回调函数
    onProgress(progress) {
      console.log("progress", progress);
    },
    // 自定义插入图片
    customInsert(res, insertFn) {
      console.log(res, "fanhuishuju");
      let url =res.data.path;
      insertFn(url);
    },
  };
  // 及时销毁 editor<重要！
  useEffect(() => {
    return () => {
      if (editor == null) return;
      editor.destroy();
      setEditor(null);
    };
  }, [editor]);
  const sumbit = () => {
    configSet({flag:'aboutus',content:html}).then(res=>{
        if(res.code==200){
            message.success(res.msg)
        }
    })
  };
  return (
    <div>
      <div style={{display:'flex',justifyContent:'flex-end',margin:'0px 30px 10px'}}>
        <Button type="primary" onClick={sumbit}>
          确认
        </Button>
      </div>
      <div style={{ border: "1px solid #ccc", zIndex: 100 }}>
        <Toolbar
          editor={editor}
          defaultConfig={toolbarConfig}
          mode="default"
          style={{ borderBottom: "1px solid #ccc" }}
        />
        <Editor
          defaultConfig={editorConfig}
          value={html}
          onCreated={setEditor}
          onChange={(editor) =>  setHtml(editor.getHtml())}
          mode="default"
          style={{ height: "500px", overflowY: "hidden" }}
        />
      </div>
      <div style={{ marginTop: "15px" }}>{html}</div>
    </div>
  );
}
