import React from "react";
import { Button, Input, Modal, Form, message } from "antd";
import { userEdit } from "../../../api/login";
import "./userList.css";
export default function ModelModule(props) {
  const { isModalOpen, queryFrom, setIsModalOpen, setqueryFrom ,setreload} = props;
  //   console.log(props, "queryFromqueryFromqueryFromqueryFrom");
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = () => {
    userEdit(queryFrom).then((res) => {
      message.success(res.msg);
      setIsModalOpen(false);
      setreload(true)
      //   search();
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  // 编辑=>监听输入框改变值
  const fromChange = (e) => {
    console.log(e, "监听输入框改变值");
    let newObj = { ...queryFrom, username: e.target.value };
    setqueryFrom(newObj);
  };
  return (
    <div>
      <Modal
        destroyOnClose
        cancelButtonProps={{ style: { display: "none" } }}
        footer={null}
        title="修改用户信息"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          className="sumbit_From_class"
          name="basic"
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="昵称"
            name="username"
            rules={[
              {
                required: true,
                message: "请输入昵称",
              },
            ]}
            initialValue={queryFrom.username}
          >
            <Input onChange={fromChange} />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 5,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
