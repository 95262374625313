const initState={
    status:false
}
  const headerReducer=(state=initState,action)=>{
    console.log(state,action,'action');
    const newState={...state}
    switch (action.type) {
        case 'status':
            newState.status=!state.status
            break;
        default:
            break;
    }
    console.log(newState,'newState');
    return newState
}

export {headerReducer}