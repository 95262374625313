import React, { useState } from "react";
import SideMenu from "../../components/sendbox/SideMenu";
import TopHeader from "../../components/sendbox/TopHeader";
import Home from "./home/Home";
import AboutUs from "./about/AboutUs";
import Reward from "./reward/Reward"
import Notice from "./notice/Notice";
import Templates from "./templates/Templates";
import Configuration from "./configuration/Configuration"
import Feedback from "./user-manage/feedback/Feedback";
import RightList from "./right-manage/RightList";
import RoleList from "./right-manage/RoleList";
import UserList from "./user-manage/UserList";
import { Route, Switch, Redirect } from "react-router-dom";
import Permission from "./permission/Permission";
import { Layout } from "antd";
import "./NewsSendBox.css";
// import Templates from "./templates/Templates";
const { Content } = Layout;
export default function NewsSandBox() {
  const [status, setStatus] = useState(false);
  return (
    <Layout>
      <SideMenu status={status}></SideMenu>
      <Layout className="site-layout">
        <TopHeader setStatus={setStatus} status={status}></TopHeader>
        <Content
          className="site-layout-background"
          style={{
            margin: "14px 12px",
            padding: 24,
            minHeight: 280,
            overflowY: "hidden",
          }}
        >
          <Switch>
            <Route path="/home" component={Home}></Route>
            <Route path="/user-manage/list" component={UserList}></Route>
            <Route path="/right-manage/role/list" component={RoleList}></Route>
            <Route
              path="/right-manage/right/list"
              component={RightList}
            ></Route>
              <Route path='/configuration' component={Configuration}></Route>
            <Route path='/templates' component={Templates}></Route>
            <Route path="/reward" component={Reward}></Route>
            <Route path="/about" component={AboutUs}></Route>
            <Route path="/notice" component={Notice}></Route>
            <Route path="/user-manage/feedback" component={Feedback}></Route>
            {/* exact严格模式(只有/才会跳转home) */}
            <Redirect from="/" to="/home" exact></Redirect>
            <Route path="*" component={Permission}></Route>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}
