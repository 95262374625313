import React from "react";
import {
  AppstoreOutlined,
  MailOutlined,
  LinkOutlined,
  MehOutlined,
  FolderOpenOutlined,
  NodeIndexOutlined,
  CheckSquareOutlined,
  AlignCenterOutlined,
  BgColorsOutlined,
  RadarChartOutlined
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
// import { useState, useEffect } from "react";
import "./SideMenu.css";
import logo from "../../assets/logo.jpg";
import { useHistory } from "react-router-dom";
// import axios from "axios";

import { connect } from "react-redux";

// import useFormItemStatus from "antd/lib/form/hooks/useFormItemStatus";
const { Sider } = Layout;
function SideMenu(props) {
  const history = useHistory();
  // const data = props;
  console.log(props, "eeeeeeeeeeeeeeeee");
  // const [collapsed, setCollapsed] = useState(false);
  // const [menu, setMenu] = useState([]);
  // const [defalutMenu, setDefalutMenu] = useState(["/home"]);
  // 自定义图标
  // const iconList={
  //   '/home':<AppstoreOutlined />,
  //   '/user-manage':<MailOutlined />,
  //   '/user-manage/list':<LinkOutlined />,

  // }
  // function getItem(label, key, icon, children, type) {
  //   return {
  //     key,
  //     icon,
  //     children,
  //     label,
  //     type,
  //   };
  // }
  // function getItemChildren(index, item) {
  //   if (item[index].children.length != []) {
  //     const moment = item[index].children.map((item) => {
  //       return item.pagepermisson==1?getItem(item.title, item.key,<LinkOutlined></LinkOutlined>):null;
  //     });
  //     return moment;
  //   } else {
  //     return null;
  //   }
  // }

  // async function forItem(item) {
  //   const itemm = [];
  //   for (let index = 0; index < item.length; index++) {
  //     console.log(item[index].key);
  //     itemm.push(
  //       getItem(
  //         item[index].title,
  //         item[index].key,
  //         <AppstoreOutlined />,
  //         await getItemChildren(index, item)
  //       )
  //     );
  //   }
  //   setMenu(itemm);
  // }
  // // 从接口返回侧边栏数据
  // useEffect(() => {
  //   axios.get("http://localhost:3000/rights?_embed=children").then((res) => {
  //     console.log(res.data);
  //     forItem(res.data);
  //   });
  // }, []);

  // 本地模拟侧边栏数据
  const items = [
    {
      label: "首页",
      key: "/home",
      icon: <AppstoreOutlined></AppstoreOutlined>,
    },
    {
      label: "文章管理",
      key: "/right-manage",
      icon: <LinkOutlined></LinkOutlined>,
      children: [
        {
          label: "分类列表",
          key: "/right-manage/role/list",
          icon: <NodeIndexOutlined />,
        },
        {
          label: "文章列表",
          key: "/right-manage/right/list",
          icon: <AppstoreOutlined></AppstoreOutlined>,
        },
      ],
    },
    {
      label: "用户管理",
      key: "/user-manage",
      icon: <MailOutlined></MailOutlined>,
      children: [
        {
          label: "用户列表",
          key: "/user-manage/list",
          icon: <MehOutlined />,
        },
        {
          label: "反馈列表",
          key: "/user-manage/feedback",
          icon: <CheckSquareOutlined />,
        },
      ],
    },
    {
      label: "模板管理",
      key: "/templates",
      icon:<AlignCenterOutlined />,
    },
    {
      label: "打赏管理",
      key: "/reward",
      icon: <BgColorsOutlined />,
    },
    {
      label: "公告管理",
      key: "/notice",
      icon: <FolderOpenOutlined />,
    },
    {
      label: "配置管理",
      key: "/configuration",
      icon: <RadarChartOutlined />,
    },
    {
      label: "关于我们",
      key: "/about",
      icon: <RadarChartOutlined />,
    },
   
  ];
  const onClick = (e) => {
    history.push({ pathname: e.key });
    props.menuAction(e.key);
  };
  return (
    <Sider
      style={{ overflowY: "scroll", height: "100%" }}
      trigger={null}
      collapsible
      collapsed={props.status}
    >
      {!props.status && (
        <div className="logo">
          <img className="logo_imgss" src={logo} alt="" />
          <div className="logo_titles">藏羚科技</div>{" "}
        </div>
      )}
      {props.status && (
        <div className="logo_img_div">
          <img className="logo_imgs" src={logo} alt="" />
        </div>
      )}
      <Menu
        theme="dark"
        onClick={onClick}
        selectedKeys={[props.menuReducer.menuKey]}
        defaultOpenKeys={["/" + props.menuReducer.menuKey.split("/")[1]]}
        mode="inline"
        items={items}
      />
    </Sider>
  );
}
const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {
    menuAction: (e) => {
      dispatch({
        type: "menuKey",
        value: e,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
