import React, { useState, useEffect } from "react";
import { Button, Table, Input, message, Typography } from "antd";
import "./notice.css";
import {
  noticeList,
  noticeDetail,
  noticeDel,
} from "../../../api/login";
import ModelModule from "./ModelModule";
export default function Notice() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, settableLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [title, settitle] = useState();
  const [dataList, setdataList] = useState([]);
  const [total, settotal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [queryFrom, setqueryFrom] = useState({});
  const [reload, setreload] = useState(false);
  // let queryFromRef = useRef();
  // useEffect(() => {
  //   queryFromRef.current = queryFrom;
  // }, [queryFrom]);

  useEffect(() => {
    settableLoading(true);
    noticeList({
      page: page,
      limit: 10,
      title: title,
    }).then((res) => {
      settableLoading(false);
      setdataList(btn(res.data.lists));
      settotal(res.data.count);
    });
  }, [page, reload]);

  const columns = [
    {
      title: "ID",
      dataIndex: "noticeId",
      align: "center",
    },
    {
      title: "公告",
      dataIndex: "title",
      align: "center",
    },
    {
      title: "排序权重",
      dataIndex: "rank",
      align: "center",
    },
    {
      title: "状态",
      dataIndex: "status",
      align: "center",
      //   render: (status) =>{status &&(<Button danger></Button>)}
      render: (status) => {
        return (
          <div>
            {status ? (
              <Button
                style={{
                  color: "rgb(64,169,255)",
                  border: "solid 1px rgb(64,169,255)",
                }}
                size="small"
              >
                正常
              </Button>
            ) : (
              <Button size="small" danger>
                禁用
              </Button>
            )}
          </div>
        );
      },
    },
    {
      title: "注册时间",
      dataIndex: "createTime",
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "operation",
      align: "center",
      render: (_, record) => {
        return (
          <Typography.Link onClick={() => edit(record)}>编辑</Typography.Link>
        );
      },
    },
  ];
  // 点击编辑
  const edit = (e) => {
    noticeDetail({ noticeId: e.noticeId }).then((res) => {
      if (res.code == 200) {
        setqueryFrom(res.data);
        setIsModalOpen(true);
      }
    });
  };
  //  点击新增
  const add = () => {
    setqueryFrom({});
    setIsModalOpen(true);
  };
  // 处理表格数据带上key
  const btn = (list) => {
    const data = [];
    for (let i = 0; i < list.length; i++) {
      data.push({
        key: i,
        noticeId: list[i].noticeId,
        title: list[i].title,
        rank: list[i].rank,
        status: list[i].status,
        createTime: list[i].createTime,
      });
    }
    return data;
  };
  // 删除row
  const start = () => {
    setLoading(true);
    noticeDel({ noticeId: selectedRowKeys.join(",") }).then((res) => {
      setSelectedRowKeys([]);
      message.success(res.msg);
      setLoading(false);
      search();
    });
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // 单元格选中
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  // 分页change
  const tableChange = (e) => {
    setpage(e.current);
  };
  // 搜索框change
  const titleChange = (e) => {
    settitle(e.target.value);
  };
  // 搜索按钮
  const search = () => {
    settableLoading(true);
    noticeList({
      page: page,
      limit: 10,
      title: title,
    }).then((res) => {
      settableLoading(false);
      setdataList(btn(res.data.lists));
      settotal(res.data.count);
    });
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div>
      <div className="table_title">
        <div
          style={{
            marginBottom: 16,
          }}
        >
          <Button
            type="danger"
            onClick={start}
            disabled={!hasSelected}
            loading={loading}
          >
            删除
          </Button>
          <span
            style={{
              marginLeft: 8,
            }}
          >
            {hasSelected ? `选中 ${selectedRowKeys.length} 项` : ""}
          </span>
          <Button
            onClick={add}
            style={{
              marginLeft: "20px",
              backgroundColor: "rgb(90,199,37)",
              color: "#FFFFFF",
              border: "solid 1px rgb(90,199,37)",
            }}
            type="default"
          >
            新增
          </Button>
        </div>
        <div className="table_title_right">
          <Input
            allowClear={true}
            onChange={titleChange}
            placeholder="请输入标题"
            value={title}
          />
          <Button
            onClick={search}
            style={{ marginLeft: "20px" }}
            type="primary"
          >
            搜索
          </Button>
        </div>
      </div>
      <Table
        rowKey={(row) => row.noticeId}
        onChange={tableChange}
        loading={tableLoading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataList}
        pagination={{
          total: total,
        }}
        scroll={{ y: "calc(100vh - 300px)" }}
      />
      <ModelModule
        setIsModalOpen={setIsModalOpen}
        setqueryFrom={setqueryFrom}
        setreload={setreload}
        isModalOpen={isModalOpen}
        queryFrom={queryFrom}
        reload={reload}
        // queryFromRef={queryFromRef.current}
      ></ModelModule>
    </div>
  );
}
