import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Modal,
  Form,
  message,
  InputNumber,
  Switch,
  Select,
  Upload,
} from "antd";
import {
  userEdit,
  noticeAdd,
  noticeEdit,
  articleAdd,
  articleEdit,
} from "../../../api/login";
import "./RightList.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-react";
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
export default function ModelModule(props) {
  const [editor, setEditor] = useState(null);
  const [params, setparams] = useState({
    name: "file",
    action: "https://api.songci.gocli.run/admin/upload",
    headers: {
      authorization: localStorage.getItem("token"),
    },
    // beforeUpload: (file) => {
    //   console.log(file);
    //   const isFLAC = file.type === "audio/flac";
    //   const isMP3 = file.type === "audio/mpeg";
    //   if (!isFLAC || !isMP3) {
    //     message.error(`请上传音频文件`);
    //   }
    //   return isFLAC || Upload.LIST_IGNORE;
    // },
  });
  const {
    isModalOpen,
    queryFrom,
    setIsModalOpen,
    setqueryFrom,
    setreload,
    reload,
    classListData,
  } = props;
  console.log(queryFrom, classListData, "子组件");
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = async (e) => {
    // 修改
    if (queryFrom.articleId) {
      console.log(queryFrom, "444444444444");
      articleEdit(queryFrom).then((res) => {
        if (res.code == 200) {
          message.success(res.msg);
          setIsModalOpen(false);
          setreload(!reload);
        }
      });
    } else {
      // 新增
      if ("status" in queryFrom) {
        // console.log(queryFrom,'点状态');
        articleAdd(queryFrom).then((res) => {
          if (res.code == 200) {
            message.success(res.msg);
            setIsModalOpen(false);
            setreload(!reload);
          }
        });
      } else {
        let newObj = { ...queryFrom, status: 1 };
        // console.log(newObj,'没点状态');
        articleAdd(newObj).then((res) => {
          if (res.code == 200) {
            message.success(res.msg);
            setIsModalOpen(false);
            setreload(!reload);
          }
        });
      }
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  // 编辑=>监听输入框改变值
  const fromChange = (e) => {
    // let newObj = { ...queryFrom, title: e.target.value };
    // setqueryFrom(newObj);
    setqueryFrom((data) => ({ ...data, title: e.target.value }));
  };
  const fromChangeOne = (editor) => {
    setqueryFrom((data) => ({ ...data, content: editor.getHtml() }));
    console.log(queryFrom);
  };
  const fromChangeTwo = (e) => {
    // let newObj = { ...queryFrom, rank: e };
    // setqueryFrom(newObj);
    setqueryFrom((data) => ({ ...data, rank: e }));
  };
  const fromChangeThree = (e) => {
    // let newObj = { ...queryFrom, status: e ? 1 : 0 };
    // setqueryFrom(newObj);
    setqueryFrom((data) => ({ ...data, status: e ? 1 : 0 }));
  };
  const selectChange = (e) => {
    // console.log(classListData[e-1].value);
    setqueryFrom((data) => ({ ...data, ClassId: classListData[e - 1].value }));
  };
  const audioOnChange = (info) => {
    if (info.file.status !== "uploading") {
      // console.log(info.fileList[0].response.data.path);
      setqueryFrom((data) => ({
        ...data,
        audio: info.fileList[0].response.data.path,
      }));
    }
  };

  //  富文本编辑器
  // 工具栏配置
  const toolbarConfig = {};
  // 编辑器配置
  const editorConfig = {
    placeholder: "请输入内容...",
    MENU_CONF: {},
  };
  editorConfig.MENU_CONF["uploadVideo"] = {
    server: "https://api.songci.gocli.run/admin/upload",
    fieldName: "file",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    // 上传之前触发
    onBeforeUpload(file) {
      return file;
    },

    // 上传进度的回调函数
    onProgress(progress) {
      console.log("progress", progress);
    },
    // 自定义插入图片
    customInsert(res, insertFn) {
      console.log(res, "fanhuishuju");
      let url = res.data.path;
      insertFn(url);
    },
  };
  editorConfig.MENU_CONF["uploadImage"] = {
    server: "https://api.songci.gocli.run/admin/upload",
    fieldName: "file",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    // 上传之前触发
    onBeforeUpload(file) {
      return file;
    },

    // 上传进度的回调函数
    onProgress(progress) {
      console.log("progress", progress);
    },
    // 自定义插入图片
    customInsert(res, insertFn) {
      console.log(res, "fanhuishuju");
      let url =  res.data.path;
      insertFn(url);
    },
  };
  // 及时销毁 editor<重要！
  useEffect(() => {
    return () => {
      if (editor == null) return;
      editor.destroy();
      setEditor(null);
    };
  }, [editor]);
  const selectDefault = () => {
    let index = classListData
      .map((item) => item.value)
      .indexOf(queryFrom.ClassId);
    return index + 1;
  };
  return (
    <div>
      <Modal
        width={800}
        destroyOnClose
        cancelButtonProps={{ style: { display: "none" } }}
        footer={null}
        title={queryFrom.articleId ? "修改公告" : "新增公告"}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Form
          className="sumbit_From_class"
          name="basic"
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="公告标题"
            name="title"
            rules={[
              {
                required: true,
                message: "请输入文章标题",
              },
            ]}
            initialValue={queryFrom.title}
          >
            <Input placeholder="请输入文章标题" onChange={fromChange} />
          </Form.Item>
          {/* 分类 */}
          <Form.Item
            label="分类"
            name="classId"
            rules={[
              {
                required: true,
                message: "请选择分类",
              },
            ]}
            initialValue={queryFrom.ClassId ? selectDefault() : null}
          >
            <Select
              allowClear
              style={{
                width: "100%",
              }}
              onChange={selectChange}
              options={classListData}
            />
          </Form.Item>
          {/* 分类 */}
          {/* 音频上传 */}
          <Form.Item label="上传音频" name="audio">
            <Upload
              {...params}
              maxCount={1}
              onChange={audioOnChange}
              showUploadList={true}
            >
              <Button>上传音频</Button>
            </Upload>
            {queryFrom.audio ? (
              <audio
                style={{
                  width: "100%",
                  height: "70px",
                }}
                id={`audio`}
                controls
                preload={"auto"}
                src={queryFrom.audio}
              ></audio>
            ) : null}

            {/* </div> */}
          </Form.Item>
          {/* 音频上传 */}
          {/* 富文本编辑器 */}
          <Form.Item label="文章内容" name="content">
            <div style={{ border: "1px solid #ccc", zIndex: 100 }}>
              <Toolbar
                editor={editor}
                defaultConfig={toolbarConfig}
                mode="default"
                style={{ borderBottom: "1px solid #ccc" }}
              />
              <Editor
                defaultConfig={editorConfig}
                value={queryFrom.content}
                onCreated={setEditor}
                onChange={(editor) => fromChangeOne(editor)}
                mode="default"
                style={{ height: "500px", overflowY: "hidden" }}
              />
            </div>
          </Form.Item>
          {/* 富文本编辑器 */}
          <Form.Item label="排序权重" name="rank">
            <InputNumber
              placeholder="请输入排序"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "请输入排序",
                },
              ]}
              min={1}
              max={255}
              defaultValue={queryFrom.rank}
              onChange={fromChangeTwo}
            />
          </Form.Item>
          <Form.Item label="状态" valuePropName="status">
            <Switch
              defaultChecked
              checked={queryFrom.status}
              onChange={fromChangeThree}
            />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 5,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
