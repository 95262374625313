import React, { useState, useEffect } from "react";
import { Button, Card, Input, message, Upload, Switch, Select } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  configGet,
  configSet,
  configsGet,
  configsSet,
} from "../../../api/login";
export default function Configuration() {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [switchs, setswitchs] = useState();
  const [setParams, setsetParams] = useState();
  const [inputs, setinputs] = useState();
  const [accesskey, setaccesskey] = useState();
  const [bucket, setbucket] = useState();
  const [secretkey, setsecretkey] = useState();
  const [selectValue, setselectValue] = useState();
  const [classListData, setclassListData] = useState([
    {
      value: "huadong",
      label: "华东",
    },
    {
      value: "huadongzhejiang2",
      label: "华东浙江2区",
    },
    {
      value: "huabei",
      label: "华北",
    },
    {
      value: "huanan",
      label: "华南",
    },
    {
      value: "beimei",
      label: "北美",
    },
    {
      value: "xinjiapo",
      label: "新加坡",
    },
    {
      value: "shouer1",
      label: "亚太首尔1区",
    },
  ]);
  const [params, setparams] = useState({
    name: "file",
    action: "https://api.songci.gocli.run/admin/upload",
    headers: {
      authorization: localStorage.getItem("token"),
    },
  });
  const toChinesNum = (num) => {
    let changeNum = [
      "零",
      "一",
      "二",
      "三",
      "四",
      "五",
      "六",
      "七",
      "八",
      "九",
    ];
    let unit = ["", "十", "百", "千", "万"];
    num = parseInt(num);
    let getWan = (temp) => {
      let strArr = temp.toString().split("").reverse();
      let newNum = "";
      let newArr = [];
      strArr.forEach((item, index) => {
        newArr.unshift(
          item === "0" ? changeNum[item] : changeNum[item] + unit[index]
        );
      });
      let numArr = [];
      newArr.forEach((m, n) => {
        if (m !== "零") numArr.push(n);
      });
      if (newArr.length > 1) {
        newArr.forEach((m, n) => {
          if (newArr[newArr.length - 1] === "零") {
            if (n <= numArr[numArr.length - 1]) {
              newNum += m;
            }
          } else {
            newNum += m;
          }
        });
      } else {
        newNum = newArr[0];
      }

      return newNum;
    };
    let overWan = Math.floor(num / 10000);
    let noWan = num % 10000;
    if (noWan.toString().length < 4) {
      noWan = "0" + noWan;
    }
    return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
  };
  const [donate, setdonate] = useState([]);
  useEffect(() => {
    configGet({ flag: "donate" }).then((res) => {
      if (res.code == 200) {
        setdonate(JSON.parse(res.data.content));
      }
    });
  }, []);
  useEffect(() => {
    configGet({ flag: "qrcode" }).then((res) => {
      if (res.code == 200) {
        setImageUrl(res.data.content);
      }
    });
    configsGet().then((res) => {
      setsetParams(res.data);
      setinputs(res.data.domain);
      setaccesskey(res.data.accesskey);
      setbucket(res.data.bucket);
      setselectValue(res.data.region);
      setsecretkey(res.data.secretkey);
      if (res.data.uploadtype == "qiniu") {
        setswitchs(true);
      } else {
        setswitchs(false);
      }
    });
  }, []);
  const inputOnchage = (e, i) => {
    let result = JSON.parse(JSON.stringify(donate));
    result[i].money = Number(e);
    setdonate(result);
  };
  const btnDonate = (e) => {
    configSet({ flag: "donate", content: JSON.stringify(donate) }).then(
      (res) => {
        if (res.code == 200) {
          message.success(res.msg);
        }
      }
    );
  };
  const fromChange = (e) => {
    setinputs(e.target.value);
    setsetParams((data) => ({ ...data, domain: e.target.value }));
  };
  const fromChangeTwo = (e) => {
    setaccesskey(e.target.value);
    setsetParams((data) => ({ ...data, accesskey: e.target.value }));
  };
  const fromChangeThree = (e) => {
    setbucket(e.target.value);
    setsetParams((data) => ({ ...data, bucket: e.target.value }));
  };
  const fromChangefour = (e) => {
    setsecretkey(e.target.value);
    setsetParams((data) => ({ ...data, secretkey: e.target.value }));
  };
  const fromChangeOne = (e) => {
    setswitchs(e);
    setsetParams((data) => ({ ...data, uploadtype: e ? "qiniu" : "local" }));
  };

  const selectChange = (e) => {
    setselectValue(e);
    setsetParams((data) => ({ ...data, region:e}));
  };
  const btnConfig = () => {
    configsSet(setParams).then((res) => {
      if (res.code == 200) {
        message.success(res.msg);
      }
    });
  };
  const handleChange = (info) => {
    if (info.file.status !== "uploading") {
      setImageUrl(info.fileList[0].response.data.path);
      configSet({
        flag: "qrcode",
        content: info.fileList[0].response.data.path,
      }).then((response) => {
        message.success(response.msg);
      });
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <Card
        title="打赏金额配置"
        style={{
          width: 400,
          height: 450,
        }}
      >
        <div>
          {donate.map((item, i) => {
            return (
              <Input
                style={{
                  borderBottom: "solid 1px #d9d9d9",
                  marginBottom: "10px",
                }}
                bordered={false}
                type="number"
                key={i}
                size="large"
                placeholder="large size"
                value={item.money}
                onChange={(e) => {
                  inputOnchage(e.target.value, i);
                }}
                prefix={"金额" + toChinesNum(i + 1) + ":"}
              />
            );
          })}
        </div>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button onClick={btnDonate} type="primary">
            提交
          </Button>
        </div>
      </Card>

      <Card
        title="打赏二维码配置"
        style={{
          width: 400,
          height: 450,
        }}
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          {...params}
          //   beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              style={{
                width: "100%",
              }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </Card>
      <Card
        title="上传资源环境配置"
        style={{
          width: 400,
          height: 450,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Switch
          checked={switchs}
          style={{ marginTop: "20px", width: "100px" }}
          checkedChildren="七牛云"
          unCheckedChildren="本地服务器"
          defaultChecked
          onChange={fromChangeOne}
        />
        <Input
          style={{ marginTop: "20px" }}
          allowClear={true}
          value={inputs}
          placeholder="请输入上传资源域名"
          onChange={fromChange}
        />
        {switchs ? (
          <Input
            style={{ marginTop: "20px" }}
            allowClear={true}
            value={accesskey}
            placeholder="请输入AK"
            onChange={fromChangeTwo}
          />
        ) : null}
        {switchs ? (
          <Input
            style={{ marginTop: "20px" }}
            allowClear={true}
            value={bucket}
            placeholder="请输入空间名"
            onChange={fromChangeThree}
          />
        ) : null}
        {switchs ? (
          <Input
            style={{ marginTop: "20px" }}
            allowClear={true}
            value={secretkey}
            placeholder="请输入SK"
            onChange={fromChangefour}
          />
        ) : null}

        {switchs ? (
          <Select
            style={{ marginTop: "20px", width: "100%" }}
            defaultValue={selectValue}
            allowClear
            placeholder="请选择区域"
            onChange={selectChange}
            options={classListData}
          />
        ) : null}

        <div
          style={{
            marginTop: "60px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button onClick={btnConfig} type="primary">
            提交
          </Button>
        </div>
      </Card>
    </div>
  );
}
