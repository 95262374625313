import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo.jpg";
import "./login.css";
import { login } from "../../api/login";
import { useState } from "react";
import { message } from "antd";
export default function Login() {
  const History = useHistory();
  const [username, setusername] = useState();
  const [password, setpassword] = useState();
  const sumbit = () => {
    login({
      username: username,
      password: password,
    }).then((res) => {
      if(res.code==200){
        message.success(res.msg);
        localStorage.setItem("token", res.data.token);
        History.push("/home");
      }
    });
  };
  const changeName = (e) => {
    setusername(e.target.value);
  };
  const changePass = (e) => {
    setpassword(e.target.value);
  };
  return (
    <div className="body_content">
      <div className="logo_content">
        <img
          style={{ width: "70px", height: "70px", borderRadius: "20px" }}
          src={logo}
          alt=""
        />
        <div className="logo_title">藏羚科技管理平台</div>
      </div>
      <div className="login-app">
        {/* <!-- 登录表单部分 --> */}
        <div className="login-body">
          <div>
            {/* <!-- 账号登录表单 --> */}
            <form action="" className="m-form">
              <input
                value={username ? username : ""}
                onChange={changeName}
                type="text"
                placeholder="请输入账号"
              />
              <input
                value={password ? password : ""}
                onChange={changePass}
                type="password"
                placeholder="登录密码"
              />
              <button onClick={sumbit} className="login-btn">
                登录
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
