import React from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import Login from "../view/login/login";
import NewsSandBox from "../view/sandbox/NewsSandBox";
// import { HistoryRouter, history } from "../utils/history";

export default function indexRouter() {
  return (
    <HashRouter >
      {/* switch用于切换路由页面 */}
      <Switch>
        <Route path='/login' component={Login}></Route>
         
        {/* <Route path='/' component={NewsSandBox}></Route> */}
        {/* 同上相同 但是render会返回一个函数 根据条件渲染出来 用来判断是否登录等 */}
        <Route path="/" render={() =>
        localStorage.getItem('token')?<NewsSandBox></NewsSandBox>
        :<Redirect to='/login'></Redirect>
        }></Route>
      </Switch>
    </HashRouter>
  );
}
