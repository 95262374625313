import React, { useState, useEffect } from "react";
import { Table, Image } from "antd";
import "./Reward.css";
import { donateList } from "../../../api/login";
// import ModelModule from "./ModelModule";
export default function Reward() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableLoading, settableLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [dataList, setdataList] = useState([]);
  const [total, settotal] = useState([]);
  useEffect(() => {
    settableLoading(true);
    donateList({
      page: page,
      limit: 10,
    }).then((res) => {
      settableLoading(false);
      setdataList(btn(res.data.lists));
      settotal(res.data.count);
    });
  }, [page]);

  const columns = [
    {
      title: "ID",
      dataIndex: "donateId",
      align: "center",
    },
    {
      title: "用户名称",
      dataIndex: "username",
      align: "center",
    },
    {
      title: "头像",
      dataIndex: "avatar",
      align: "center",
      render: (avatar) => {
        return (
          <Image
            width={50}
            height={50}
            style={{ borderRadius: "5px" }}
            src={avatar}
            preview={{
              src: avatar,
            }}
          />
        );
      },
    },
    {
      title: "打赏金额",
      dataIndex: "money",
      align: "center",
    },
    {
      title: "订单号",
      dataIndex: "orderNo",
      align: "center",
    },
    {
      title: "打赏时间",
      dataIndex: "payTime",
      align: "center",
    },
  ];

  // 处理表格数据带上key
  const btn = (list) => {
    const data = [];
    for (let i = 0; i < list.length; i++) {
      data.push({
        key: i,
        donateId: list[i].donateId,
        avatar: list[i].avatar,
        money: list[i].money,
        orderNo: list[i].orderNo,
        username: list[i].username,
        payTime: list[i].payTime,
      });
    }
    return data;
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // 单元格选中
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  // 分页change
  const tableChange = (e) => {
    setpage(e.current);
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div>
      <Table
        rowKey={(row) => row.donateId}
        onChange={tableChange}
        loading={tableLoading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataList}
        pagination={{
          total: total,
        }}
        scroll={{ y: "calc(100vh - 300px)" }}
      />
    </div>
  );
}
