import './App.css';
import IndexRouter from './router/indexRouter';
//  import IndexRouter from './typescript/index.jsx'
//导入store
import {store,persistor} from './redux/store'
import {Provider} from 'react-redux'
import {PersistGate} from "redux-persist/integration/react"
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <IndexRouter></IndexRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
