import React from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  // DownOutlined,
  // SmileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import logo from "../../assets/logo.jpg";
import { Layout, Dropdown, Avatar } from "antd";
import { useHistory } from "react-router-dom";
// import { useState } from "react";
// import store from "../../redux/store";
// import '../../App.css'
import { connect } from "react-redux";
const { Header } = Layout;
function TopHeader(props) {
  const history = useHistory();
  const { status, setStatus } = props;
  // const [collapsed, setCollapsed] = useState(false);
  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            localStorage.removeItem("token");
            history.push("./login");
            props.menuAction("/home");
          }}
        >
          退出登录
        </div>
      ),
    },
  ];
  return (
    <div>
      <Header
        className="site-layout-background"
        style={{
          padding: "0 16px",
        }}
      >
        {React.createElement(status ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: "trigger",
          onClick: () => setStatus(!status),
        })}
        <div
          style={{
            float: "right",
            margin: "0 20px 0 0",
            minWidth: "170px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              marginRight: "10px",
              fontWeight: "600",
              fontSize: "16px",
              paddingTop: "1px",
            }}
          >
            欢迎Admin回来
          </span>
          <Dropdown
            menu={{
              items,
            }}
            placement="bottom"
            arrow
          >
            <Avatar
              src={
                JSON.parse(localStorage.getItem("userInfo"))
                  ?JSON.parse(localStorage.getItem("userInfo")).avatar
                  : logo
              }
              size={40}
              icon={<UserOutlined />}
            />
          </Dropdown>
        </div>
      </Header>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    menuAction: (e) => {
      dispatch({
        type: "menuKey",
        value: e,
      });
    },
  };
};
// 发送方不需要接受 所以第一个参数传null就行
export default connect(null, mapDispatchToProps)(TopHeader);
