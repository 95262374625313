import React, { useState, useEffect } from "react";
import { Button, Table, Input, message, Typography } from "antd";
import "./Templates.css";
import {
  //   articleList,
  articleDel,
  //   userList,
  //   articleDetail,
  taskList,
  taskDel,
} from "../../../api/login";
import ModelModule from "./ModelModule";
export default function Templates() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, settableLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [title, settitle] = useState();
  const [dataList, setdataList] = useState([]);
  const [total, settotal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [queryFrom, setqueryFrom] = useState({});
  const [reload, setreload] = useState(false);
  const [classListData, setclassListData] = useState([
    {
      label: "读书提醒",
      value: "41588",
    },
    {
      label: "日程提醒",
      value: "1677",
    },
  ]);
  //   const [userListDate, setuserListDate] = useState()

  // let queryFromRef = useRef();
  // useEffect(() => {
  //   queryFromRef.current = queryFrom;
  // }, [queryFrom]);

  useEffect(() => {
    settableLoading(true);
    taskList({
      page: page,
      limit: 10,
      title: title,
    }).then((res) => {
      settableLoading(false);
      setdataList(btn(res.data.lists));
      settotal(res.data.count);
    });
  }, [page, reload]);



  const columns = [
    {
      title: "ID",
      dataIndex: "taskId",
      align: "center",
    },
    // {
    //   title: "消息标题",
    //   dataIndex: "title",
    //   align: "center",
    // },
    {
      title: "模板类型",
      dataIndex: "template",
      align: "center",
      render: (template) => {
        return (
          <div>
            {template == "41588" ? <div>读书提醒</div> : <div>日程提醒</div>}
          </div>
        );
      },
    },
    {
      title: "模板内容",
      dataIndex: "content",
      align: "center",
      render: (content) => {
        return (
          <div>
            {JSON.parse(content).phrase5 ? (
              <div style={{ textAlign: "center" }}>
                <div>提醒日期:&nbsp;{JSON.parse(content).date2}</div>
                <div>事项主题:&nbsp;{JSON.parse(content).phrase5}</div>
                <div>提醒事项:&nbsp;{JSON.parse(content).thing3}</div>
                <div>备注:&nbsp;{JSON.parse(content).thing9}</div>
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <div>书名:&nbsp;{JSON.parse(content).thing1}</div>
                <div>作者:&nbsp;{JSON.parse(content).thing2}</div>
                <div>备注:&nbsp;{JSON.parse(content).thing3}</div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      align: "center",
      render: (status) => {
        return (
          <div>
            {status == 1 ? (
              <Button
                style={{
                  color: "rgb(64,169,255)",
                  border: "solid 1px rgb(64,169,255)",
                }}
                size="small"
              >
                已发送
              </Button>
            ) : status == 2 ? (
              <Button size="small" danger>
                发送失败
              </Button>
            ) : (
              <Button size="small">未发送</Button>
            )}
          </div>
        );
      },
    },
    {
      title: "发送时间",
      dataIndex: "sendTime",
      align: "center",
      render: (sendTime) => {
        return (
          <div>
            {sendTime == "1970-01-01 08:00:00" ? (
              <div>立即发送</div>
            ) : (
              <div>{sendTime}</div>
            )}
          </div>
        );
      },
    },
    // ,
    // {
    //   title: "操作",
    //   dataIndex: "operation",
    //   align: "center",
    //   render: (_, record) => {
    //     return (
    //       <Typography.Link onClick={() => edit(record)}>编辑</Typography.Link>
    //     );
    //   },
    // },
  ];
  //   useEffect(() => {
  //     userList({
  //       page: 1,
  //       limit: 100000,
  //     }).then((res) => {
  //       var arr = res.data.lists.map((item, i) => {
  //         return {
  //           label: item.username,
  //           value: item.userId,
  //         };
  //       });
  //       setuserListDate(arr);
  //     });
  //   }, []);
  // const getClassList = () => {
  //   classList({
  //     page: 1,
  //     limit: 10,
  //   }).then((res) => {
  //     var arr = res.data.map((item, i) => {
  //       return {
  //         label: item.className,
  //         value: item.classId,
  //         index: i,
  //       };
  //     });
  //     setclassListData(arr);
  //   });
  // };
  // 点击编辑
  //   const edit = (e) => {
  //     articleDetail({ articleId: e.articleId }).then((res) => {
  //       if (res.code == 200) {
  //         setqueryFrom(res.data);
  //         // getClassList();
  //         setIsModalOpen(true);
  //       }
  //     });
  //   };
  //  点击新增
  const add = () => {
    setqueryFrom({});
    // getClassList();
    setIsModalOpen(true);
  };
  // 处理表格数据带上key
  const btn = (list) => {
    const data = [];
    for (let i = 0; i < list.length; i++) {
      data.push({
        key: i,
        taskId: list[i].taskId,
        title: list[i].title,
        template: list[i].template,
        content: list[i].content,
        rank: list[i].rank,
        status: list[i].status,
        sendTime: list[i].sendTime,
      });
    }
    return data;
  };
  // 删除row
  const start = () => {
    setLoading(true);
    taskDel({ taskId: selectedRowKeys.join(",") }).then((res) => {
      setSelectedRowKeys([]);
      message.success(res.msg);
      setLoading(false);
      search();
    });
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // 单元格选中
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  // 分页change
  const tableChange = (e) => {
    setpage(e.current);
  };
  // 搜索框change
  const titleChange = (e) => {
    settitle(e.target.value);
  };
  // 搜索按钮
  const search = () => {
    settableLoading(true);
    taskList({
      page: page,
      limit: 10,
      title: title,
    }).then((res) => {
      settableLoading(false);
      setdataList(btn(res.data.lists));
      settotal(res.data.count);
    });
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div>
      <div className="table_title">
        <div
          style={{
            marginBottom: 16,
          }}
        >
          <Button
            type="danger"
            onClick={start}
            disabled={!hasSelected}
            loading={loading}
          >
            删除
          </Button>
          <span
            style={{
              marginLeft: 8,
            }}
          >
            {hasSelected ? `选中 ${selectedRowKeys.length} 项` : ""}
          </span>
          <Button
            onClick={add}
            style={{
              marginLeft: "20px",
              backgroundColor: "rgb(90,199,37)",
              color: "#FFFFFF",
              border: "solid 1px rgb(90,199,37)",
            }}
            type="default"
          >
            新增
          </Button>
        </div>
        <div className="table_title_right">
          <Input
            allowClear={true}
            onChange={titleChange}
            placeholder="请输入标题"
            value={title}
          />
          <Button
            onClick={search}
            style={{ marginLeft: "20px" }}
            type="primary"
          >
            搜索
          </Button>
        </div>
      </div>
      <Table
        rowKey={(row) => row.taskId}
        onChange={tableChange}
        loading={tableLoading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataList}
        pagination={{
          total: total,
        }}
        scroll={{ y: "calc(100vh - 300px)" }}
      />
      <ModelModule
        setIsModalOpen={setIsModalOpen}
        setqueryFrom={setqueryFrom}
        setreload={setreload}
        isModalOpen={isModalOpen}
        queryFrom={queryFrom}
        reload={reload}
        classListData={classListData}
        // queryFromRef={queryFromRef.current}
      ></ModelModule>
    </div>
  );
}
