import axios from "axios";
import { message } from "antd";
// import history from './history'
const service = axios.create({
  // baseURL: "/api",
  baseURL: "https://fucksoto.bodtin.top/api",
  timeout: 5000,
});
// 请求前操作
service.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("token")) {
      config.headers.Authorization = localStorage.getItem("token");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 请求后操作
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code != 200) {
      message.error(res.msg || "Error");
      if (res.code == 500) {
        localStorage.removeItem("token");
        return Promise.reject(new Error(res.msg || "Error"));
      }else if(res.code==400){
        return res
      }
    }else{
      return res;
    }
  },
  (error) => {
    message.error(error.msg || "Error");
    return Promise.reject(error);
  }
);
export default service;
