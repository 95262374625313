import React from "react";
import {
  Card,
  Avatar,
  Upload,
  message,
  Modal,
  Form,
  Input,
  Button,
} from "antd";
import { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { changeInfo, info, changePwd } from "../../../api/login";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
const { Meta } = Card;
function Home(props) {
  const history = useHistory();
  const [userInfo, seuserInfo] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [old_password, setold_password] = useState();
  const [new_password, setnew_password] = useState();
  const [params, setparams] = useState({
    name: "file",
    action: "https://api.songci.gocli.run/admin/upload",
    headers: {
      authorization: localStorage.getItem("token"),
    },
  });
  useEffect(() => {
    info().then((res) => {
      localStorage.setItem("userInfo", JSON.stringify(res.data));
      seuserInfo(res.data);
    });
  }, []);
  function onChange(info) {
    if (info.file.status !== "uploading") {
      changeInfo({ avatar: info.fileList[0].response.data.path }).then(
        (response) => {
          message.success(response.msg);
          getInfo();
        }
      );
    }
  }
  const getInfo = () => {
    info().then((res) => {
      localStorage.setItem("userInfo", JSON.stringify(res.data));
      seuserInfo(res.data);
    });
  };
  const editPass = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = () => {
    changePwd({ old_password: old_password, new_password: new_password }).then(
      (res) => {
       if(res.code==200){
        // logout()
        message.success('修改成功,请重新登录!')
        localStorage.removeItem("token");
        history.push("./login");
        props.menuAction("/home");
       }
      }
    );
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const oldPassChange = (e) => {
    console.log(e.target.value);
    setold_password(e.target.value);
  };
  const nowPassChange = (e) => {
    setnew_password(e.target.value);
  };
  return (
    <div>
      <Card
        style={{
          width: 350,
          maxHeight: 450,
          marginLeft: "40px",
        }}
        cover={
          <img
            alt="example"
            src={
              userInfo
                ? userInfo.avatar
                : "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
            }
          />
        }
      >
        <Meta
          avatar={
            <Upload
              {...params}
              maxCount={1}
              onChange={onChange}
              showUploadList={false}
            >
              <Avatar
                src={
                  userInfo
                    ? userInfo.avatar
                    : "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                }
              />
            </Upload>
          }
          title={userInfo ? userInfo.username : "admin"}
          description={<a onClick={editPass}>修改密码</a>}
        />
      </Card>
      <Modal
        footer={null}
        destroyOnClose
        cancelButtonProps={{ style: { display: "none" } }}
        title="修改密码"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="原密码"
            name="old_password"
            rules={[
              {
                required: true,
                message: "请输入原密码",
              },
            ]}
            initialValue={old_password}
          >
            <Input.Password onChange={oldPassChange} />
          </Form.Item>

          <Form.Item
            label="新密码"
            name="new_password"
            rules={[
              {
                required: true,
                message: "请输入新密码",
              },
            ]}
            initialValue={new_password}
          >
            <Input.Password onChange={nowPassChange} />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 5,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              确认
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    menuAction: (e) => {
      dispatch({
        type: "menuKey",
        value: e,
      });
    },
  };
};
export default connect(null, mapDispatchToProps)(Home);