import React, { useState, useEffect } from "react";
import { Table, Image } from "antd";
import "./Feedback.css";
import { feedbackList } from "../../../../api/login";
// import ModelModule from "./ModelModule";
export default function Feedback() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableLoading, settableLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [dataList, setdataList] = useState([]);
  const [total, settotal] = useState([]);
  useEffect(() => {
    settableLoading(true);
    feedbackList({
      page: page,
      limit: 10,
    }).then((res) => {
      settableLoading(false);
      setdataList(btn(res.data.lists));
      settotal(res.data.count);
    });
  }, [page]);

  const columns = [
    {
      title: "ID",
      dataIndex: "userId",
      align: "center",
    },
    {
      title: "用户名称",
      dataIndex: "username",
      align: "center",
    },
    {
      title: "头像",
      dataIndex: "avatar",
      align: "center",
      render: (avatar) => {
        return (
          <Image
            width={50}
            height={50}
            style={{ borderRadius: "5px" }}
            src={avatar}
            preview={{
              src: avatar,
            }}
          />
        );
      },
    },
    {
      title: "标题",
      dataIndex: "title",
      align: "center",
    },
    {
      title: "微信号",
      dataIndex: "wxcode",
      align: "center",
    },
    {
      title: "手机号",
      dataIndex: "mobile",
      align: "center",
    },
    {
      title: "内容",
      dataIndex: "content",
      align: "center",
    },
    {
      title: "反馈时间",
      dataIndex: "createTime",
      align: "center",
    },
  ];

  // 处理表格数据带上key
  const btn = (list) => {
    const data = [];
    for (let i = 0; i < list.length; i++) {
      data.push({
        key: i,
        userId: list[i].userId,
        avatar: list[i].avatar,
        title: list[i].title,
        wxcode: list[i].wxcode,
        mobile: list[i].mobile,
        content: list[i].content,
        createTime: list[i].createTime,
      });
    }
    return data;
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // 单元格选中
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  // 分页change
  const tableChange = (e) => {
    setpage(e.current);
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div>
      <Table
        rowKey={(row) => row.key}
        onChange={tableChange}
        loading={tableLoading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataList}
        pagination={{
          total: total,
        }}
        scroll={{ y: "calc(100vh - 300px)" }}
      />
    </div>
  );
}
