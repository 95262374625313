const initState={
    menuKey:localStorage.getItem('persist:persist')?JSON.parse(JSON.parse(localStorage.getItem('persist:persist')).menuReducer).menuKey:'/home'
}
 const menuReducer=(state=initState,action)=>{
    // console.log(state,action,'action');
    // console.log(JSON.parse(JSON.parse(localStorage.getItem('persist:persist')).menuReducer).menuKey,'actionsss')
    const newState={...state}
    switch (action.type) {
        case 'menuKey':
            newState.menuKey=action.value
            break;
        default:
            break;
    }
    return newState
}
export {menuReducer}