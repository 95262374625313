// 使用react
// 1.引入redux   npm i redux 
// 2.createStore(reducer ) 
// import {  createStore } from "redux";
// const reducer =(prevState,action)=>{
//     console.log(action,'77777')
//     return prevState
// }
// const store =createStore(reducer)
// export default store


// 使用react-redux
// import {  createStore } from "redux";
import { legacy_createStore,combineReducers } from 'redux'
import {headerReducer} from './reducer/header'
import {menuReducer} from './reducer/menu'

// 数据持久化
import {persistStore,persistReducer} from "redux-persist"
import storage from 'redux-persist/lib/storage'

const persistConfig={
    // 数据持久化存储
    key:'persist',
    storage,
    whitelist:['menuReducer']
};
const reducers=combineReducers({
    //合并多个reducer
    headerReducer,
    menuReducer,
})
const persistedReducer=persistReducer(persistConfig,reducers)
const store =legacy_createStore(persistedReducer)
const persistor =persistStore(store)
export {
    store,
    persistor
} 