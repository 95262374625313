import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Modal,
  Form,
  message,
  InputNumber,
  Switch,
  Select,
  Upload,
  DatePicker,
} from "antd";
import {
  userEdit,
  noticeAdd,
  noticeEdit,
  articleAdd,
  articleEdit,
  taskAdd,
} from "../../../api/login";
import "./Templates.css";
import moment from "moment";
import "moment/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";
// import { Editor, Toolbar } from "@wangeditor/editor-for-react";
// import "@wangeditor/editor/dist/css/style.css"; // 引入 css
export default function ModelModule(props) {
  const getNowFormatDate = () => {
    //获取当前时间
    let date = new Date();
    let seperator1 = "-"; //年月日之间的分隔
    let seperator2 = ":"; //时分秒之间的分隔
    let month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let strDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let strHours =
      date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let strMinutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let strSeconds =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    let currentdate =
      date.getFullYear() +
      seperator1 +
      month +
      seperator1 +
      strDate +
      " " +
      strHours +
      seperator2 +
      strMinutes +
      seperator2 +
      strSeconds; //拼接一下
    return currentdate; //返回
  };
  const [defaultTime, setdefaultTime] = useState(getNowFormatDate());
  const [defaultTimeOne, setdefaultTimeOne] = useState(getNowFormatDate());
  const {
    isModalOpen,
    queryFrom,
    setIsModalOpen,
    setqueryFrom,
    setreload,
    reload,
    classListData,
  } = props;
  console.log(queryFrom, classListData, "子组件");
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = async (e) => {
    let result;
    // // 判断是否选择执行时间
    // if (!queryFrom.sendTime) {
    //   setqueryFrom((data) => ({ ...data, sendTime: getNowFormatDate() }));
    // }
    if (queryFrom.template == "41588") {
      //提醒
      result = {
        template: queryFrom.template,
        title: queryFrom.title,
        userId: "all",
        sendTime: queryFrom.sendTime ? queryFrom.sendTime : getNowFormatDate(),
        content: JSON.stringify({
          thing1: queryFrom.thing1,
          thing2: queryFrom.thing2,
          thing3: queryFrom.thing3,
        }),
      };
    } else {
      //日程
      result = {
        template: queryFrom.template,
        title: queryFrom.title,
        userId: "all",
        sendTime: queryFrom.sendTime ? queryFrom.sendTime : getNowFormatDate(),
        content: JSON.stringify({
          date2: queryFrom.date2,
          phrase5: queryFrom.phrase5,
          thing3: queryFrom.thing3,
          thing9: queryFrom.thing9,
        }),
      };
    }
    console.log(result, "resultresultresultresult");
    // 修改
    if (queryFrom.taskId) {
      console.log(queryFrom, "444444444444");
      articleEdit(queryFrom).then((res) => {
        if (res.code == 200) {
          message.success(res.msg);
          setIsModalOpen(false);
          setreload(!reload);
        }
      });
    } else {
      // 新增
      // if ("status" in queryFrom) {
      // console.log(queryFrom,'点状态');
      taskAdd(result).then((res) => {
        if (res.code == 200) {
          message.success(res.msg);
          setIsModalOpen(false);
          setreload(!reload);
        }
      });
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  // 编辑=>监听输入框改变值
  const fromChange = (e) => {
    // let newObj = { ...queryFrom, title: e.target.value };
    // setqueryFrom(newObj);
    setqueryFrom((data) => ({ ...data, thing1: e.target.value }));
  };
  const fromChangeThree = (e) => {
    setqueryFrom((data) => ({ ...data, title: e.target.value }));
  };
  const fromChangeOne = (e) => {
    setqueryFrom((data) => ({ ...data, thing2: e.target.value }));
  };
  const fromChangeTwo = (e) => {
    setqueryFrom((data) => ({ ...data, thing3: e.target.value }));
  };
  const selectChange = (e) => {
    setqueryFrom((data) => ({ ...data, template: e }));
  };
  const fromChangeFour = (e) => {
    setqueryFrom((data) => ({ ...data, phrase5: e.target.value }));
  };
  const fromChangeFive = (e) => {
    setqueryFrom((data) => ({ ...data, thing9: e.target.value }));
  };
  const selectDefault = () => {
    let index = classListData
      .map((item) => item.value)
      .indexOf(queryFrom.template);
    return index + 1;
  };
  // 时间选择框
  const onchageTime = () => {
    setdefaultTime("1970-01-01 08:00:00");
    setqueryFrom((data) => ({ ...data, sendTime: "1970-01-01 08:00:00" }));
  };
  const onOkPicker = (_, time) => {
    setdefaultTime(time);
    setqueryFrom((data) => ({ ...data, sendTime: time }));
  };
  const onOkPickerOne = (_, time) => {
    setdefaultTimeOne(time);
    setqueryFrom((data) => ({ ...data, date2: time }));
  };

  return (
    <div>
      <Modal
        width={800}
        destroyOnClose
        cancelButtonProps={{ style: { display: "none" } }}
        footer={null}
        title={queryFrom.taskId ? "修改模板" : "新增模板"}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Form
          className="sumbit_From_class"
          name="basic"
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {/* 分类 */}
          <Form.Item
            label="模板类型"
            name="template"
            rules={[
              {
                required: true,
                message: "请选择分类",
              },
            ]}
            initialValue={queryFrom.template ? selectDefault() : null}
          >
            <Select
              allowClear
              style={{
                width: "100%",
              }}
              onChange={selectChange}
              options={classListData}
            />
          </Form.Item>
          <Form.Item
            label="任务标题"
            name="title"
            rules={[
              {
                required: true,
                message: "请输入标题",
              },
            ]}
            initialValue={queryFrom.thing1}
          >
            <Input
              allowClear={true}
              placeholder="请输入标题"
              onChange={fromChangeThree}
            />
          </Form.Item>
          {/* 分类 */}
          {/* 读书提醒 */}
          {queryFrom.template == "41588" ? (
            <Form.Item
              label="书名"
              name="thing1"
              rules={[
                {
                  required: true,
                  message: "请输入书名",
                },
              ]}
              initialValue={queryFrom.thing1}
            >
              <Input
                allowClear={true}
                placeholder="汉字、数字、字母或符号组合(20字符以内)"
                onChange={fromChange}
              />
            </Form.Item>
          ) : null}

          {queryFrom.template == "41588" ? (
            <Form.Item
              label="作者"
              name="thing2"
              rules={[
                {
                  required: true,
                  message: "请输入作者",
                },
              ]}
              initialValue={queryFrom.thing2}
            >
              <Input
                allowClear={true}
                placeholder="汉字、数字、字母或符号组合(20字符以内)"
                onChange={fromChangeOne}
              />
            </Form.Item>
          ) : null}

          {queryFrom.template == "41588" ? (
            <Form.Item
              label="备注"
              name="thing3"
              rules={[
                {
                  required: true,
                  message: "请输入备注",
                },
              ]}
              initialValue={queryFrom.thing3}
            >
              <Input
                allowClear={true}
                placeholder="汉字、数字、字母或符号组合(20字符以内)"
                onChange={fromChangeTwo}
              />
            </Form.Item>
          ) : null}
          {/* 读书提醒 */}
          {/* 日程提醒 */}
          {queryFrom.template == "1677" ? (
            <Form.Item
              label="提醒日期"
              name="date2"
              rules={[
                {
                  required: true,
                  message: "请输入提醒日期",
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                locale={locale}
                value={moment(defaultTimeOne, "YYYY-MM-DD HH:mm:ss")}
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="选择日期"
                showTime
                onChange={onOkPickerOne}
              />
            </Form.Item>
          ) : null}
          {queryFrom.template == "1677" ? (
            <Form.Item
              label="事项主题"
              name="phrase5"
              rules={[
                {
                  required: true,
                  message: "请输入主题",
                },
              ]}
              initialValue={queryFrom.phrase5}
            >
              <Input
                allowClear={true}
                placeholder="5个字以内纯汉字"
                onChange={fromChangeFour}
              />
            </Form.Item>
          ) : null}
          {queryFrom.template == "1677" ? (
            <Form.Item
              label="提醒事项"
              name="thing3"
              rules={[
                {
                  required: true,
                  message: "请输入提醒事项",
                },
              ]}
              initialValue={queryFrom.thing3}
            >
              <Input
                allowClear={true}
                placeholder="汉字、数字、字母或符号组合(20字符以内)"
                onChange={fromChangeTwo}
              />
            </Form.Item>
          ) : null}
          {queryFrom.template == "1677" ? (
            <Form.Item
              label="备注"
              name="thing9"
              rules={[
                {
                  required: true,
                  message: "请输入备注",
                },
              ]}
              initialValue={queryFrom.thing9}
            >
              <Input
                allowClear={true}
                placeholder="汉字、数字、字母或符号组合(20字符以内)"
                onChange={fromChangeFive}
              />
            </Form.Item>
          ) : null}
          {/* 日程提醒 */}
          {/* 执行时间 */}
          <Form.Item label="执行时间" name="sendTime">
            <div style={{ display: "flex" }}>
              <DatePicker
                allowClear={false}
                style={{ width: "100%" }}
                locale={locale}
                value={moment(defaultTime, "YYYY-MM-DD HH:mm:ss")}
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="选择日期"
                showTime
                onChange={onOkPicker}
              />
              <Button
                style={{ marginLeft: "10px" }}
                onClick={onchageTime}
                type="primary"
              >
                立即推送
              </Button>
            </div>
          </Form.Item>

          {/* 日程提醒 */}
          <Form.Item
            wrapperCol={{
              offset: 5,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
