import React from "react";
import "./permission.css";
import permission from "../../../assets/404.png";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
function Permission(props) {
  const history = useHistory();
  const btnHome = () => {
    history.push({ pathname: "/home" });
    props.menuAction("/home");
  };
  return (
    <div class="wrapper">
      <div class="http404-container">
        <img class="pic" src={permission} alt="" />
        <div class="title">抱歉，您要访问的页面丢失了</div>
        <div class="desc">
          请检查您输入的网址是否正确，请点击以下按钮返回首页
        </div>
        <div onClick={btnHome} class="btn">
          返回首页
        </div>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    menuAction: (e) => {
      dispatch({
        type: "menuKey",
        value: e,
      });
    },
  };
};
export default connect(null, mapDispatchToProps)(Permission);
