import React, { useState, useEffect } from "react";
import { Button, Table, Input, message, Typography, Image } from "antd";
import "./userList.css";
import { userList, userDel } from "../../../api/login";
import ModelModule from "./ModelModule";
export default function UserList() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, settableLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [username, setusername] = useState();
  const [dataList, setdataList] = useState([]);
  const [total, settotal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [queryFrom, setqueryFrom] = useState({});
  const [reload, setreload] = useState(false);
  useEffect(() => {
    settableLoading(true);
    userList({
      page: page,
      limit: 10,
      username: username,
    }).then((res) => {
      settableLoading(false);
      setdataList(btn(res.data.lists));
      settotal(res.data.count);
    });
  }, [page, reload]);

  const columns = [
    {
      title: "ID",
      dataIndex: "userId",
      align: "center",
    },
    {
      title: "用户昵称",
      dataIndex: "username",
      align: "center",
    },
    {
      title: "头像",
      dataIndex: "avatar",
      align: "center",
      render: (avatar) => {
        return (
          <Image
            width={50}
            height={50}
            style={{ borderRadius: "5px" }}
            src={avatar}
            preview={{
              src: avatar,
            }}
          />
          // <Avatar
          //   src={"https://api.songci.gocli.run" + avatar}
          //   size={40}
          //   icon={<UserOutlined />}
          // />
        );
      },
    },
    {
      title: "注册时间",
      dataIndex: "createTime",
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "operation",
      align: "center",
      render: (_, record) => {
        return (
          <Typography.Link onClick={() => edit(record)}>编辑</Typography.Link>
        );
      },
    },
  ];
  // 点击编辑
  const edit = (e) => {
    // seteditusername('2222222')
    // let obj =[];
    // console.log(obj,'eeeeeeee');
    setqueryFrom({ ...e });
    // console.log(queryFrom, "queryFrom");
    setIsModalOpen(true);
  };
  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };
  // const onFinish = () => {
  //   userEdit(queryFrom).then((res) => {
  //     message.success(res.msg);
  //     setIsModalOpen(false);
  //     search();
  //   });
  // };
  // const onFinishFailed = (errorInfo) => {
  //   console.log("Failed:", errorInfo);
  // };
  // // 编辑=>监听输入框改变值
  // const fromChange = (e) => {
  //   console.log(e, "监听输入框改变值");
  //   let newObj = { ...queryFrom, username: e.target.value };
  //   setqueryFrom(newObj);
  // };
  // 处理表格数据带上key
  const btn = (list) => {
    const data = [];
    for (let i = 0; i < list.length; i++) {
      data.push({
        key: i,
        userId: list[i].userId,
        username: list[i].username,
        avatar: list[i].avatar,
        createTime: list[i].createTime,
      });
    }
    return data;
  };
  // 删除row
  const start = () => {
    setLoading(true);
    userDel({ userId: selectedRowKeys.join(",") }).then((res) => {
      setSelectedRowKeys([]);
      message.success(res.msg);
      setLoading(false);
      search();
    });
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // 单元格选中
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  // 分页change
  const tableChange = (e) => {
    setpage(e.current);
  };
  // 搜索框change
  const usernameChange = (e) => {
    setusername(e.target.value);
  };
  // 搜索按钮
  const search = () => {
    settableLoading(true);
    userList({
      page: page,
      limit: 10,
      username: username,
    }).then((res) => {
      settableLoading(false);
      setdataList(btn(res.data.lists));
      settotal(res.data.count);
    });
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div>
      <div className="table_title">
        <div
          style={{
            marginBottom: 16,
          }}
        >
          <Button
            type="danger"
            onClick={start}
            disabled={!hasSelected}
            loading={loading}
          >
            删除
          </Button>
          <span
            style={{
              marginLeft: 8,
            }}
          >
            {hasSelected ? `选中 ${selectedRowKeys.length} 项` : ""}
          </span>
        </div>
        <div className="table_title_right">
          <Input
            allowClear={true}
            onChange={usernameChange}
            placeholder="请输入用户昵称"
            value={username}
          />
          <Button
            onClick={search}
            style={{ marginLeft: "20px" }}
            type="primary"
          >
            搜索
          </Button>
        </div>
      </div>
      <Table
        rowKey={(row) => row.userId}
        onChange={tableChange}
        loading={tableLoading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataList}
        pagination={{
          total: total,
        }}
        scroll={{ y: "calc(100vh - 300px)" }}
      />
      {/* <Modal
        cancelButtonProps={{ style: { display: "none" } }}
        footer={null}
        title="修改用户信息"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          className="sumbit_From_class"
          name="basic"
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="昵称"
            name="username"
            rules={[
              {
                required: true,
                message: "请输入昵称",
              },
            ]}
          >
            <Input onChange={fromChange} value={editusername} />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 5,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </Form>
      </Modal> */}
      <ModelModule
        setIsModalOpen={setIsModalOpen}
        setqueryFrom={setqueryFrom}
        setreload={setreload}
        isModalOpen={isModalOpen}
        queryFrom={queryFrom}
      ></ModelModule>
    </div>
  );
}
